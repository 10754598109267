.ConfirmWarningModal {
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    .img {
        width: 30%;       
    }
    .title {
        font-size: 30px;
        color: #595959;
    }
    .content {
        font-size: 20px;
        margin-top: 5px;
        text-align: center;
        color: #545454;
    }
}